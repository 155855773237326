import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Context } from '../../context/UserContext';
import { login } from '../../services/user.service';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(245 27 208)',
    },
    secondary: {
      main: '#4c0363',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  form: {
    width: '100%',
    marginTop: theme.spacing(30),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'rgb(245 27 208)',
    fontWeight: 600,
    fontSize: '16px',
    background: '#4c0363',
  },
  disabledButton: {
    background: '#0000001f',
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const user = useContext(Context);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');

  let history = useHistory();
  const validateUserName = (userName) => {
    if (userName === '') {
      return 'Username is required';
    }
    return '';
  };

  const validatePassword = (password) => {
    if (password === '') {
      return 'Password is required';
    }
    return '';
  };

  // reset login status
  useEffect(() => {
    if (user.loggedIn) {
      history.push('/');
    }
  }, [history, user.loggedIn]);

  // function handleChange(e) {
  //     const { name, value } = e.target;
  //     setInputs(inputs => ({ ...inputs, [name]: value }));
  // }

  const isFormValid = useMemo(() => {
    const isUserEmpty = validateUserName(userName) === '';
    const isPasswordEmpty = validatePassword(password) === '';
    return isUserEmpty && isPasswordEmpty;
  }, [userName, password]);

  function handleSubmit(e) {
    e.preventDefault();

    if (userName && password) {
      login(userName, password, user.setUser, user.setLoggedIn);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xs">
        <div className={classes.paper}>
          <div className={classes.form}>
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                className={classes.input}
                fullWidth
                id="email"
                label="Benutzername"
                name="username"
                autoComplete="username"
                autoFocus
                value={userName}
                onChange={(event) => setUserName(event.target.value)}
              />

              <TextField
                variant="outlined"
                margin="normal"
                className={classes.input}
                fullWidth
                name="password"
                label="Passwort"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
                disabled={!isFormValid}
                classes={{ disabled: classes.disabledButton }}
              >
                Login
              </Button>
            </form>
            {errorMessage !== '' ? (
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            ) : null}
          </div>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export { LoginPage };

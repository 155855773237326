import authHeader from '../helpers/auth-header';
import { handleResponse } from '../helpers/response';

export function getAllMeals(setMeals) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    credentials: 'include',
  };
  fetch(`${process.env.REACT_APP_API_URL}/meal`, requestOptions)
    .then(handleResponse)
    .then((meals) => setMeals(meals));
}

export function getAllMealsOfWeek(week, setMeals) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    credentials: 'include',
  };
  fetch(`${process.env.REACT_APP_API_URL}/meal/week/${week}`, requestOptions)
    .then(handleResponse)
    .then((meals) => setMeals(meals));
}

export function createInfoMeal(info, date) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },

    credentials: 'include',
    body: JSON.stringify({
      mealType: 'INFO',
      date: date,
      info: [{ info: info }],
    }),
  };
  return fetch(`${process.env.REACT_APP_API_URL}/meal`, requestOptions).then(handleResponse);
}

export function copyMeal(meal, date, newType, endDate) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },

    credentials: 'include',
    body: JSON.stringify({
      mealType: newType.toUpperCase(),
      date,
      endDate,
      id: meal.id,
    }),
  };
  return fetch(`${process.env.REACT_APP_API_URL}/meal/copy`, requestOptions).then(handleResponse);
}

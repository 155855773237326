import { handleResponse } from '../helpers/response';
import authHeader from '../helpers/auth-header';

// import  logout from '../_helpers/response'

export function login(username, password, setUser, setLoggedIn) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name: username, password: password }),
    // credentials: "include"
  };

  fetch(`${process.env.REACT_APP_API_URL}/user/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
      setLoggedIn(true);
      return user;
    });
}

export function getUsers(setUsers) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    credentials: 'include',
  };
  fetch(`${process.env.REACT_APP_API_URL}/user`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      setUsers(user);

      return user;
    });
}

export function logoutNow(setLoggedIn) {
  localStorage.removeItem('user');
  setLoggedIn(false);
}

import authHeader from '../helpers/auth-header';
import { handleResponse } from '../helpers/response';

export function getAllFoods(setFoods) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    credentials: 'include',
  };
  fetch(`${process.env.REACT_APP_API_URL}/food`, requestOptions)
    .then(handleResponse)
    .then((foods) => setFoods(foods));
}

export function createFoodRelations(food, mealType, date, user, quantity, calories) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },

    credentials: 'include',
    body: JSON.stringify({
      user: { name: user },
      food: { name: food, kcal: calories },
      meal: { mealType: mealType.toUpperCase(), date: date },
      quantity: quantity,
    }),
  };
  return fetch(`${process.env.REACT_APP_API_URL}/foodrelation`, requestOptions).then(
    handleResponse
  );
}

export function deleteFoodRelations(foodRelationId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),

    credentials: 'include',
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/foodrelation/${foodRelationId}`,
    requestOptions
  ).then(handleResponse);
}

export function updateEaten(foodRelationId) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),

    credentials: 'include',
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/foodrelation/${foodRelationId}/eaten`,
    requestOptions
  ).then(handleResponse);
}

import React, { useEffect, useState } from 'react';
import MealTable from '../../components/MealTable';
import {
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { createInfoMeal, getAllMealsOfWeek } from '../../services/meal.service';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFoodRelations, getAllFoods } from '../../services/food.service';
import MomentUtils from '@date-io/moment';
import 'moment/locale/de';
import { getUsers } from '../../services/user.service';
import { getAllDates } from '../../services/dates.service';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
  },
}));

export default function HomePage() {
  const [meals, setMeals] = useState([]);
  const [foods, setFoods] = useState([]);
  const [actualWeek, setActualWeek] = useState(moment().isoWeek());
  const [addFoodOpen, setAddFoodOpen] = React.useState(false);
  const [foodInputSelect, setFoodInputSelect] = React.useState('');
  const [foodInput, setFoodInput] = React.useState('');
  const [mealType, setMealType] = React.useState('');
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState('melanie');
  const [amount, setAmount] = React.useState(1);
  const [calories, setCalories] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState(moment());
  const [datesOfWeek, setDatesOfWeek] = React.useState(moment());

  const classes = useStyles();

  useEffect(() => {
    getAllDates(setDatesOfWeek, actualWeek);
    getAllMealsOfWeek(actualWeek, setMeals);
    getAllFoods(setFoods);
  }, [actualWeek, setMeals]);

  useEffect(() => {
    getUsers(setUsers);
  }, []);

  function changeWeek(change) {
    const changedWeek = actualWeek + change;
    setActualWeek(changedWeek);
  }

  function addFood() {
    setAddFoodOpen(false);

    if (mealType === 'info') {
      createInfoMeal(
        foodInputSelect !== foodInput ? foodInput : foodInputSelect,
        selectedDate.format('YYYY-MM-DD')
      ).then(() => getAllMealsOfWeek(actualWeek, setMeals));
    } else {
      createFoodRelations(
        foodInputSelect !== foodInput ? foodInput : foodInputSelect,
        mealType,
        selectedDate.format('YYYY-MM-DD'),
        selectedUser,
        amount,
        calories
      ).then(() => getAllMealsOfWeek(actualWeek, setMeals));
    }

    setAmount(1);
    setMealType('');
    setCalories(0);
    setFoodInputSelect('');
    setSelectedUser('melanie');
    getAllMealsOfWeek(actualWeek, setMeals);
  }

  return (
    <>
      <Container style={{ marginTop: '2em' }}>
        <Button onClick={() => changeWeek(-1)}>&lt;</Button>
        <Typography style={{ display: 'inline-block', width: '50px' }}>
          {' '}
          KW {actualWeek}{' '}
        </Typography>
        <Button onClick={() => changeWeek(1)}>&gt;</Button>
        <div style={{ display: 'inline-block' }}>
          <Chip
            style={{ margin: '5px' }}
            label="rot=ok"
            color="secondary"
            className="caloriesChip"
          />
          <Chip
            style={{ margin: '5px' }}
            label="blau=nicht"
            color="primary"
            className="caloriesChip"
          />
        </div>
        <IconButton
          onClick={() => setAddFoodOpen(!addFoodOpen)}
          style={{ display: 'inline-block', float: 'right' }}
        >
          <AddIcon />
        </IconButton>
      </Container>
      <MealTable
        mealsOfWeek={meals.mealsOfWeek}
        datesOfWeek={datesOfWeek}
        setMeals={setMeals}
        actualWeek={actualWeek}
      />
      <Dialog
        open={addFoodOpen}
        onClose={() => setAddFoodOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Hinzufügen</DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'de'}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              format="DD.MM.YYYY"
              margin="dense"
              value={selectedDate}
              onChange={setSelectedDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="select-mealtype-label">Essenstyp</InputLabel>
            <Select
              labelId="select-mealtype-label"
              value={mealType}
              onChange={(event) => setMealType(event.target.value)}
            >
              <MenuItem value={'breakfast'}>Frühstück</MenuItem>
              <MenuItem value={'lunch'}>Mittagessen</MenuItem>
              <MenuItem value={'dinner'}>Abendessen</MenuItem>
              <MenuItem value={'snack'}>Snack</MenuItem>
              <MenuItem value={'info'}>Info</MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            freeSolo
            blurOnSelect
            value={foodInputSelect}
            onChange={(event, newValue) => {
              foods.map((food) => {
                if (food.name === newValue) {
                  setCalories(food.kcal);
                }
                return food;
              });
              setFoodInputSelect(newValue);
            }}
            inputValue={foodInput}
            onInputChange={(event, newInputValue) => {
              setFoodInput(newInputValue);
            }}
            options={foods.map((food) => food.name)}
            // getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Essen" margin="dense" autoFocus />
            )}
          />

          <TextField
            margin="dense"
            label="Anzahl"
            type="number"
            fullWidth
            value={amount}
            onChange={(event) => setAmount(event.target.value)}
          />
          <TextField
            margin="dense"
            label="Kalorien"
            type="number"
            fullWidth
            value={calories}
            onChange={(event) => setCalories(event.target.value)}
          />
          <Autocomplete
            blurOnSelect
            value={selectedUser}
            onChange={(event, newValue) => {
              setSelectedUser(newValue);
            }}
            options={users}
            renderInput={(params) => (
              <TextField {...params} label="Benutzer" margin="dense" autoFocus />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddFoodOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={() => addFood()} color="primary">
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { HomePage };

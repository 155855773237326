import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MealTableCell from './MealTableCell';
import { Chip, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import { deleteFoodRelations } from '../services/food.service';
import { getAllMealsOfWeek } from '../services/meal.service';
import { deleteInfo, updateInfo } from '../services/info.service';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(date, breakfast, lunch, dinner, snack, info) {
  return { date, breakfast, lunch, dinner, snack, info };
}

function createTableData(mealsOfWeek, datesOfWeek, setRows) {
  const rows = [];
  if (mealsOfWeek) {
    rows.push(
      createData(
        'Mo ' + datesOfWeek.monday,
        mealsOfWeek.monday ? mealsOfWeek.monday.breakfast : undefined,
        mealsOfWeek.monday ? mealsOfWeek.monday.lunch : undefined,
        mealsOfWeek.monday ? mealsOfWeek.monday.dinner : undefined,
        mealsOfWeek.monday ? mealsOfWeek.monday.snack : undefined,
        mealsOfWeek.monday ? mealsOfWeek.monday.info : undefined
      )
    );
    rows.push(
      createData(
        'Di ' + datesOfWeek.tuesday,
        mealsOfWeek.tuesday ? mealsOfWeek.tuesday.breakfast : undefined,
        mealsOfWeek.tuesday ? mealsOfWeek.tuesday.lunch : undefined,
        mealsOfWeek.tuesday ? mealsOfWeek.tuesday.dinner : undefined,
        mealsOfWeek.tuesday ? mealsOfWeek.tuesday.snack : undefined,
        mealsOfWeek.tuesday ? mealsOfWeek.tuesday.info : undefined
      )
    );
    rows.push(
      createData(
        'Mi ' + datesOfWeek.wednesday,
        mealsOfWeek.wednesday ? mealsOfWeek.wednesday.breakfast : undefined,
        mealsOfWeek.wednesday ? mealsOfWeek.wednesday.lunch : undefined,
        mealsOfWeek.wednesday ? mealsOfWeek.wednesday.dinner : undefined,
        mealsOfWeek.wednesday ? mealsOfWeek.wednesday.snack : undefined,
        mealsOfWeek.wednesday ? mealsOfWeek.wednesday.info : undefined
      )
    );
    rows.push(
      createData(
        'Do ' + datesOfWeek.thursday,
        mealsOfWeek.thursday ? mealsOfWeek.thursday.breakfast : undefined,
        mealsOfWeek.thursday ? mealsOfWeek.thursday.lunch : undefined,
        mealsOfWeek.thursday ? mealsOfWeek.thursday.dinner : undefined,
        mealsOfWeek.thursday ? mealsOfWeek.thursday.snack : undefined,
        mealsOfWeek.thursday ? mealsOfWeek.thursday.info : undefined
      )
    );
    rows.push(
      createData(
        'Fr ' + datesOfWeek.friday,
        mealsOfWeek.friday ? mealsOfWeek.friday.breakfast : undefined,
        mealsOfWeek.friday ? mealsOfWeek.friday.lunch : undefined,
        mealsOfWeek.friday ? mealsOfWeek.friday.dinner : undefined,
        mealsOfWeek.friday ? mealsOfWeek.friday.snack : undefined,
        mealsOfWeek.friday ? mealsOfWeek.friday.info : undefined
      )
    );
    rows.push(
      createData(
        'Sa ' + datesOfWeek.saturday,
        mealsOfWeek.saturday ? mealsOfWeek.saturday.breakfast : undefined,
        mealsOfWeek.saturday ? mealsOfWeek.saturday.lunch : undefined,
        mealsOfWeek.saturday ? mealsOfWeek.saturday.dinner : undefined,
        mealsOfWeek.saturday ? mealsOfWeek.saturday.snack : undefined,
        mealsOfWeek.saturday ? mealsOfWeek.saturday.info : undefined
      )
    );
    rows.push(
      createData(
        'So ' + datesOfWeek.sunday,
        mealsOfWeek.sunday ? mealsOfWeek.sunday.breakfast : undefined,
        mealsOfWeek.sunday ? mealsOfWeek.sunday.lunch : undefined,
        mealsOfWeek.sunday ? mealsOfWeek.sunday.dinner : undefined,
        mealsOfWeek.sunday ? mealsOfWeek.sunday.snack : undefined,
        mealsOfWeek.sunday ? mealsOfWeek.sunday.info : undefined
      )
    );
    setRows(rows);
  }
}

export default function MealTable({ mealsOfWeek, datesOfWeek, setMeals, actualWeek }) {
  const [rows, setRows] = React.useState([]);
  const [editInfoOpen, setEditInfoOpen] = React.useState(false);
  const [infoText, setInfoText] = React.useState('');
  const [info, setInfo] = React.useState('');
  const classes = useStyles();

  useEffect(() => createTableData(mealsOfWeek, datesOfWeek, setRows), [
    datesOfWeek,
    mealsOfWeek,
    setRows,
  ]);

  function calculateCaloriesOfMeal(meal, eaten) {
    let calories = 0;
    meal.forEach((meal) => {
      if (meal.food) {
        meal.food.forEach((food) => {
          if (food.user.name === 'melanie' && food.eaten === eaten) {
            calories += food.kcal * food.quantity;
          }
        });
      }
    });
    return calories;
  }

  function calculateCaloriesOfDay(row, eaten) {
    let caloriesOfDay = 0;
    if (row.breakfast) {
      caloriesOfDay += calculateCaloriesOfMeal(row.breakfast, eaten);
    }
    if (row.lunch) {
      caloriesOfDay += calculateCaloriesOfMeal(row.lunch, eaten);
    }
    if (row.dinner) {
      caloriesOfDay += calculateCaloriesOfMeal(row.dinner, eaten);
    }
    if (row.snack) {
      caloriesOfDay += calculateCaloriesOfMeal(row.snack, eaten);
    }
    return caloriesOfDay;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '10%', borderLeft: '1px solid rgba(224, 224, 224, 1)' }} />
              <TableCell style={{ width: '15%', borderLeft: '1px solid rgba(224, 224, 224, 1)' }}>
                Frühstück
              </TableCell>
              <TableCell style={{ width: '15%', borderLeft: '1px solid rgba(224, 224, 224, 1)' }}>
                Mittagessen
              </TableCell>
              <TableCell style={{ width: '15%', borderLeft: '1px solid rgba(224, 224, 224, 1)' }}>
                Abendessen
              </TableCell>
              <TableCell style={{ width: '15%', borderLeft: '1px solid rgba(224, 224, 224, 1)' }}>
                Snack
              </TableCell>
              <TableCell style={{ width: '15%', borderLeft: '1px solid rgba(224, 224, 224, 1)' }}>
                Info
              </TableCell>
              <TableCell style={{ width: '5%', borderLeft: '1px solid rgba(224, 224, 224, 1)' }}>
                Kalorien
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell style={{ maxWidth: '200px' }}>{row.date}</TableCell>
                <MealTableCell data={row.breakfast} setMeals={setMeals} actualWeek={actualWeek} />
                <MealTableCell data={row.lunch} setMeals={setMeals} actualWeek={actualWeek} />
                <MealTableCell data={row.dinner} setMeals={setMeals} actualWeek={actualWeek} />
                <MealTableCell data={row.snack} setMeals={setMeals} actualWeek={actualWeek} />
                <TableCell
                  style={{
                    maxWidth: '200px',
                    overflowWrap: 'break-word',
                    borderLeft: '1px solid rgba(224, 224, 224, 1)',
                    textAlign: 'center',
                    verticalAlign: 'top',
                  }}
                >
                  {row.info &&
                    row.info.map(
                      (infoList, index) =>
                        infoList.info &&
                        infoList.info.map((info, index) => (
                          <Chip
                            key={index}
                            label={info.info}
                            variant="outlined"
                            style={{ margin: '5px' }}
                            className="foodChip"
                            onDelete={() => {
                              deleteInfo(info.id).then(() =>
                                getAllMealsOfWeek(actualWeek, setMeals)
                              );
                            }}
                            onClick={() => {
                              setInfoText(info.info);
                              setInfo(info);
                              setEditInfoOpen(true);
                            }}
                          />
                          // <span
                          //   key={index}
                          //   style={{ display: 'block' }}
                          //   onClick={() => {
                          //     setInfo(info.info);
                          //     setEditInfoOpen(true);
                          //   }}
                          // >
                          //   {info.info}
                          // </span>
                        ))
                    )}
                </TableCell>
                <TableCell style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }}>
                  <Chip
                    key={'uneaten-' + index}
                    style={{ margin: '5px' }}
                    label={calculateCaloriesOfDay(row, false)}
                    color="secondary"
                  />
                  <Chip
                    key={'eaten-' + index}
                    style={{ margin: '5px' }}
                    label={calculateCaloriesOfDay(row, true)}
                    color="primary"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={editInfoOpen}
        onClose={() => setEditInfoOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Bearbeiten</DialogTitle>
        <DialogContent>
          <TextField
            label="Info"
            value={infoText}
            onChange={(event) => setInfoText(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditInfoOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              setEditInfoOpen(false);
              updateInfo({ id: info.id, info: infoText }).then(() =>
                getAllMealsOfWeek(actualWeek, setMeals)
              );
            }}
            color="primary"
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { MealTable };

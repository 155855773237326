import authHeader from '../helpers/auth-header';
import { handleResponse } from '../helpers/response';

export function deleteInfo(infoId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),

    credentials: 'include',
  };
  return fetch(`${process.env.REACT_APP_API_URL}/info/${infoId}`, requestOptions).then(
    handleResponse
  );
}

export function updateInfo(info) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(info),
    credentials: 'include',
  };
  return fetch(`${process.env.REACT_APP_API_URL}/info`, requestOptions).then(handleResponse);
}

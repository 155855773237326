import React from 'react';

export const Context = React.createContext({});

export default function UserContext(props) {
  const [user, setUser] = React.useState({});
  const [loggedIn, setLoggedIn] = React.useState(false);
  return (
    <Context.Provider value={{ user, setUser, loggedIn, setLoggedIn }}>
      {props.children}
    </Context.Provider>
  );
}

import authHeader from '../helpers/auth-header';
import { handleResponse } from '../helpers/response';

export function getAllDates(setDates, week) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    credentials: 'include',
  };
  fetch(`${process.env.REACT_APP_API_URL}/dates/week/${week}`, requestOptions)
    .then(handleResponse)
    .then((dates) => setDates(dates));
}

import {
  Avatar,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
} from '@material-ui/core';
import React from 'react';
import { deleteFoodRelations, updateEaten } from '../services/food.service';
import { copyMeal, getAllMealsOfWeek } from '../services/meal.service';
import IconButton from '@material-ui/core/IconButton';
import { FileCopy } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
  },
}));

export default function MealTable({ data, setMeals, actualWeek }) {
  const [mealType, setMealType] = React.useState('');
  const [copyOpen, setCopyOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(moment());
  const [selectedEndDate, setSelectedEndDate] = React.useState(moment());
  const [selectedMeal, setSelectedMeal] = React.useState(moment());
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();

  function calculateCaloriesOfMeal(meal, eaten) {
    let calories = 0;
    meal.food.forEach((food) => {
      if (food.user.name === 'melanie' && food.eaten === eaten) {
        calories += food.kcal * food.quantity;
      }
    });
    return calories;
  }

  return (
    <>
      <TableCell
        style={{
          maxWidth: '200px',
          overflowWrap: 'break-word',
          borderLeft: '1px solid rgba(224, 224, 224, 1)',
          textAlign: 'center',
          verticalAlign: 'top',
        }}
      >
        {data &&
          data.map(
            (meal, index) =>
              meal.food && (
                <div key={index}>
                  {meal.food.map((food, index) => (
                    <Chip
                      key={index}
                      style={{ margin: '5px', height: 'auto', minHeight: '32px' }}
                      avatar={
                        <Avatar
                          style={{
                            background: food.user.name === 'melanie' ? 'blueviolet' : 'green',
                          }}
                        >
                          {food.user.name.substr(0, 1).toUpperCase()}
                        </Avatar>
                      }
                      label={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span
                            style={{
                              whiteSpace: 'break-spaces',
                              paddingLeft: '5px',
                              paddingRight: '5px',
                            }}
                          >
                            {food.quantity + 'x ' + food.name}
                          </span>
                          <span>{food.quantity * food.kcal}</span>
                        </div>
                      }
                      color={food.eaten ? 'primary' : 'secondary'}
                      onDelete={() => {
                        deleteFoodRelations(food.foodRelationId).then(() =>
                          getAllMealsOfWeek(actualWeek, setMeals)
                        );
                      }}
                      className="foodChip"
                      onClick={() => {
                        updateEaten(food.foodRelationId).then(() =>
                          getAllMealsOfWeek(actualWeek, setMeals)
                        );
                      }}
                    />
                  ))}
                  <div>
                    <Chip
                      key={'uneaten-' + index}
                      style={{ margin: '5px' }}
                      label={calculateCaloriesOfMeal(meal, false)}
                      color="secondary"
                      className="caloriesChip"
                    />
                    <Chip
                      key={'eaten-' + index}
                      style={{ margin: '5px' }}
                      label={calculateCaloriesOfMeal(meal, true)}
                      color="primary"
                      className="caloriesChip"
                    />
                  </div>
                  <IconButton
                    onClick={() => {
                      setSelectedMeal(meal);
                      setCopyOpen(true);
                    }}
                    style={{ float: 'right' }}
                  >
                    <FileCopy />
                  </IconButton>
                </div>
              )
          )}
      </TableCell>
      <Dialog
        open={copyOpen}
        onClose={() => setCopyOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Kopieren</DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'de'}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              format="DD.MM.YYYY"
              margin="dense"
              value={selectedDate}
              onChange={setSelectedDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              error={selectedEndDate.isBefore(selectedDate)}
              helperText={selectedEndDate.isBefore(selectedDate) && 'endDate is before startDate'}
              disableToolbar
              fullWidth
              format="DD.MM.YYYY"
              margin="dense"
              value={selectedEndDate}
              onChange={setSelectedEndDate}
              KeyboardButtonProps={{
                'aria-label': 'change end date',
              }}
            />
          </MuiPickersUtilsProvider>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="select-mealtype-label">Essenstyp</InputLabel>
            <Select
              labelId="select-mealtype-label"
              value={mealType}
              onChange={(event) => setMealType(event.target.value)}
            >
              <MenuItem value={'breakfast'}>Frühstück</MenuItem>
              <MenuItem value={'lunch'}>Mittagessen</MenuItem>
              <MenuItem value={'dinner'}>Abendessen</MenuItem>
              <MenuItem value={'snack'}>Snack</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCopyOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              copyMeal(
                selectedMeal,
                selectedDate.format('YYYY-MM-DD'),
                mealType,
                selectedEndDate.format('YYYY-MM-DD')
              )
                .then((response) => getAllMealsOfWeek(actualWeek, setMeals))
                .catch((error) => {
                  enqueueSnackbar('Something went wrong', {
                    variant: 'error',
                  });
                });
              setCopyOpen(false);
            }}
            color="primary"
            disabled={mealType === '' || selectedEndDate.isBefore(selectedDate)}
          >
            Kopieren
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
